.App {
  text-align: center;
}
body {
  font-family: "Libre Franklin";
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  transition: background-color 0.3s ease;
}

.navbar.bg-light {
  background-color: rgba(255, 255, 255, 0.8); /* Translucent */
}

.navbar.bg-light.scrolled {
  background-color: white; /* White background after scroll */
}

@media (max-width: 992px) {
  .navbar.bg-light {
    background-color: white; /* Always white in mobile view */
  }
}

.nav-text {
  font-size: 18px;
  color: #333;
  transition: color 0.3s;
}

.nav-text:hover {
  color: #007bff;
}

.dropdown-item {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
}

.dropdown-item:hover {
  color: #007bff;
}

.mil-icon-frame {
  margin-bottom: 20px;
  animation: bounce 2s infinite;
}
    @keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.amazing-button {
  background-color: #279EE1;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  /* font-weight: bold; */
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  cursor: pointer;
  outline: none;
}

.nav-item {
  border-radius: 5px;
  color: white;
  padding: 1px 10px;
  font-size: 14px;
  /* font-weight: bold; */
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  cursor: pointer;
  outline: none;
}

.amazing-button:hover {
  background-color: #2575fc;
}

.amazing-button:active {
  background-color: #6a11cb;
}
.mil-banner {
  position: relative;
  overflow: hidden;
}
.mil-background-image {
  width: 100%;
  transition: transform 2s ease;
}
.swiper-slide-active .mil-background-image {
  transform: scale(1.1);
}
.mil-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.mil-banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  text-align: center;
}

.scroll-down-arrow {
  position: absolute;
  bottom: 20px;
  left: 49%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #fff;
  z-index: 2;
  animation: bounce 2s infinite;
}
